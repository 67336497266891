@import "../../sassUtils/indexUtils.scss";

.container {
    width: 100%;
}

.title {
    display: flex;
    justify-content: center;
    color: #0C2136;
    font-weight: 300;
    font-size: 1.5rem;
}

.row {
    display: flex;
    gap: 1em;
    justify-content: space-between;

    .mapa {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 1em;
        padding-right: 4rem;
    }

    .buttonCoordenadas {
        background: transparent linear-gradient(90deg, #0C2136 0%, #244C72 100%) 0% 0% no-repeat padding-box;
        border-radius: 21px;
        bottom: 2.5em;
        color: #ffffff;
        font-weight: 300;
        height: 2em;
        left: 3em;
        position: absolute;
        text-transform: capitalize;
        width: 14em;
    }
}

.errorMessage {
    color: $red;
}

.column {
    width: 45%;
}

.inputBig {
    width: max-content;
}

.inputSm {
    width: max-content;
}

.buttons {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    max-width: 50%;

    .buttonRow {
        display: flex;
        flex-wrap: wrap;
        gap: 1em;
        justify-content: space-around;
        width: 100%;

        button {
            width: 40%;
        }
    }
}

.mapaModal {
    display: flex;
    justify-content: center;
    width: 90%;
    flex-direction: column;
}

.searchBoxContainer {
    margin-bottom: 1rem;
    padding-right: 2rem;
}

.searchBox {
    border: 1px solid #000;
    width: 100%;
    height: 32px;
    padding: 0 12px;
    border-radius: 3px;
    font-size: 14px;
    outline: none;
    text-overflow: ellipses;
    z-index: 100;
    color: #000;
}