.container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    justify-content: center;
    margin: 2em 0;

    .form {
        display: flex;
        flex-wrap: wrap;
        gap: 1em;
        justify-content: center;
        margin: 2em auto 0 auto;
        width: 50%;
    }
}

.title {
    display: flex;
    justify-content: center;
    color: #0C2136;
    font-weight: 300;
    font-size: 1.5rem;
}

.label {
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
    margin-bottom: 8px;
}

.buttonRow {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.input {
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    border-bottom: 1px solid #000;
    height: 1.1876em;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
    margin-bottom: 16px;
}

.input:focus {
    outline: 0;
}

.button:hover {
    background: linear-gradient(90deg, #13d5a5 0%, #13c5d5 100%) !important;
}

.closeButton {
    background: #bbb !important;
}

.closeButton:hover {
    background: #777 !important;
}