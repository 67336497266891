@import "../../sassUtils/indexUtils.scss";

.container {
    font-family: "Roboto", sans-serif;
    width: 80%;
    margin: 2em 0;
}

.divider {
    width: 100%;
}

.result {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    hr {
        opacity: 0.2;
        width: 90%;
    }
}

.status {
    display: flex;
    align-items: center;
    width: 80%;
}

.icon {
    margin-right: 1em;
}

.registro {
    flex-grow: 1;
    margin: 0.75rem 0;
}

.checkIcon {
    color: #0C2136;
}

.updateIcon {
    width: 32px;
    height: 32px;
}

.cancelIcon {
    color: #f00;
}

.editIcon {
    height: 2em;
    cursor: pointer;
}

.closeButtonContainer {
    display: flex;
    justify-content: center;

    button {
        margin-top: 3em;
        width: 20%;
    }
}

.button {
    color: inherit;
    width: 274px;
    height: 40px;
    background: #ddd;
    border-radius: 21;
    opacity: 1;
    font-weight: 300;
}