.container {
    display: flex;
    margin: 2em 0;
}

.column {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    justify-content: center;
    padding: 0.5rem;
    position: relative;
    width: 50%;

    form {
        display: flex;
        flex-wrap: wrap;
        gap: 1em;
        justify-content: center;
    }

    .text {
        display: flex;
        flex-wrap: wrap;
        gap: 1em;
        justify-content: center;
    }
}

.imageContainer {
    overflow: hidden;
    height: 30vh;
    width: 45%;

    .image {
        cursor: pointer;
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}

.imageDNIContainer {
    overflow: hidden;
    height: 30vh;
    width: 90%;

    .image {
        cursor: pointer;
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}

.inputRow {
    display: flex;
    gap: 1em;
    justify-content: space-between;
    width: 100%;

    .input {
        width: 50%;
    }
}

.buttonRow {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    justify-content: space-between;

    button {
        width: 40%;
    }
}

.loading {
    width: 100%;
    display: flex;
    justify-content: center;

}