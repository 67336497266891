.form {
    align-items: center;
    display: flex;
    gap: 2em;
    height: 20px;
    width: 100%;

    .input {
        margin-top: 0;
        width: 10%;
    }
}