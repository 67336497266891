@import "../../sassUtils/indexUtils.scss";

.div {
    align-items: center;
    height: calc(100vh - 150px);
    justify-content: center;

    .dashboard {
        padding: 1em;
        padding-left: 2em;

        .selfie {
            max-height: 350px;
            max-width: 95%;
            overflow: hidden;
        }

        .dataContainer {
            align-items: start;
            display: flex;

            .personalDataDiv {
                display: flex;
                flex-wrap: wrap;
                gap: 1.2em;
                width: 40%;

                .paragraph {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 0.3em;
                    width: 100%;

                    .title {
                        font-weight: 700;
                        width: 100%;
                    }

                    .data {
                        color: $lightgray;
                    }
                }
            }
        }

        .card {
            align-items: center;
            background-color: rgb(227, 223, 212);
            border-radius: 10px;
            gap: 0.5em;
            justify-content: center;
            padding: 2em 5em;
            text-align: center;
            max-width: 150px;
            width: 100%;

            h2 {
                margin: 5px;
                width: 100%;
            }

            p {
                margin: 5px;
                color: $gray;
                width: 100%;
            }

            img {
                margin: 5px;
                width: 40px;
            }
        }

        .switchButton {
            align-items: center;
            background-color: $blue;
            border: none;
            color: white;
            cursor: pointer;
            font-size: 1em;
            height: 40px;
            justify-content: center;
            margin: -0.2em 1em 1em 0;
            width: 300px;
        }

        .geoLocation {
            align-items: center;
            display: flex;
            border: 1px solid rgb(179, 179, 179);
            color: rgb(179, 179, 179);
            height: 300px;
            justify-content: center;
            width: 400px;
        }
    }
}