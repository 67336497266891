@import "../../sassUtils/indexUtils.scss";

.column {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    justify-content: center;
    padding: 0.5rem;
    position: relative;
    width: 100%;
}

.userImage {
    height: 40vh;
    width: 45%;

    .imageWrapper {
        overflow: hidden;
        height: 90%;
        width: 100%;
        border: 1px solid #000;
        background-color: darkgray;
        cursor: -moz-zoom-in;
        cursor: -webkit-zoom-in;
        cursor: zoom-in;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }

        img:hover {
            transform: scale(2);
        }
    }

    .imageLabel {
        margin-top: 10px;
    }
}

.inputs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    .checkbox {
        padding-top: 1em;
    }

    .errorMessage {
        color: $red;
    }
}
