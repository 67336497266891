.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2em 0;
}

.column {
    gap: 1em;
    justify-content: center;
    padding: 0.5rem;
    width: 100%;
}

.imageContainer {
    height: 200px;
    overflow: hidden;
    width: min-content;

    .image {
        cursor: pointer;
        height: 200px;
        width: auto;
    }
}

.inputRow {
    display: flex;
    gap: 1em;
    justify-content: space-between;
    width: 100%;

    .input {
        width: 50%;
    }
}

.buttonRow {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    justify-content: space-between;
    width: 100%;

    button {
        width: 40% !important;
    }
}