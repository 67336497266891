@import "../../sassUtils/indexUtils.scss";

.section {
    // height calc minus header height and button div height
    @include sectionTableNoPadding;
    padding: 0 3em;
}

.container {
    display: flex;
    height: 100%;
}

.column {
    align-items: center;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    height: 80%;
    margin: auto;
    width: 50%;
}

.columnRenaper {
    align-items: center;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    margin: auto;
    width: 45%;
}

.imageRow {
    display: flex;
    gap: 4em;
    justify-content: center;
    height: 220px;
    text-align: center;
    padding: 1rem;
    width: 100%;
}

.form {
    display: flex;
    flex-wrap: wrap;
    gap: 2em;
    justify-content: center;
    width: 100%;
}

.inputDiv {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    justify-content: center;
    width: 100%;
}

.input {
    width: 85%;
}

.checkingData {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -2em;
    text-align: center;
    width: max-content;

    p {
        width: 100%;
    }
}

.imageRow .rotar {
    bottom: 4em;
    cursor: pointer;
    margin: 0 1.5em 0 1em;
    max-height: 25px;
    max-width: 25px;
    position: relative;
}

.imageContainer {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 50%;

    label {
        cursor: pointer;
    }
}

.imageRotate {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 95%;

    .dni {
        cursor: pointer;
        max-width: 270px;
    }
}

.imageRotateSelfie {
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    width: 70%;

    .selfie {
        cursor: pointer;
        margin: 0 3em;
    }
}

.error {
    display: block;
    color: red;
    width: 100%;
}

.imageContainer img {
    max-width: 100%;
    max-height: 220px;
    width: max-content;
    border-radius: 5px;
}

.inputRow {
    display: flex;
    justify-content: space-between;
}

.buttonRow {
    text-align: center;
    width: 100%;
}

.modalRecorte {
    max-height: 80vh;
}

.cropperContainer {
    display: flex;
    width: 80%;
    justify-content: space-between;
    max-height: 60vh;
    align-items: center;
}

.cropper {
    max-width: 400px;
    max-height: 60vh;
    margin: 1rem;
}

.closeButtonContainer {
    display: flex;
    justify-content: center;
}

.button {
    width: 10rem;
    padding: 6px 16px !important;
    height: 40px !important;
    color: #ffffff !important;
    background: transparent linear-gradient(90deg, #0C2136 0%, #244C72 100%) 0% 0% no-repeat padding-box;
    border-radius: 2rem !important;
    font-weight: 300 !important;
    margin-bottom: 1rem !important;
    text-transform: capitalize !important;
}

.closeButton {
    background: #bbb !important;
    width: 10rem;
    padding: 6px 16px !important;
    height: 40px !important;
    color: #ffffff !important;
    border-radius: 2rem !important;
    font-weight: 300 !important;
    margin: 0 1rem 1rem !important;
}

.closeButton:hover {
    background: #777 !important;
}

.title {
    display: flex;
    justify-content: center;
    color: #0C2136;
    font-weight: lighter;
    font-size: 1.5rem;
}