.homeContainer {
    align-items: center;
    display: flex;
    height: calc(95vh - 150px);
    justify-content: center;

    .background {
        display: flex;
        flex-wrap: wrap;
        gap: 2em;
        justify-content: center;
        width: 50%;

        h2 {
            color: gray;
            opacity: 0.5;
            text-align: center;
            width: 100%;
        }

        img {
            opacity: 0.5;
            width: 50%;
        }
    }
}